import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Flex, Input, Space } from 'antd';

import { AseedText } from '../../../../../AseedTypography/AseedText/AseedText';
import { AseedTitle } from '../../../../../AseedTypography/AseedTitle/AseedTitle';
import MessageBubble from '../../../../MessageBubble';
import { type SearchScope, SearchSettings } from './SearchSettings';
import { Message, assistantService } from 'src/services/assistant.service';

import styles from './ReportAssistant.module.scss';

interface ReportAssistantProps {
    reportId: string;
}

const getPlaceholderByScope = (useInternet: boolean, scope: SearchScope): string => {
    const prefix = useInternet ? '􀆪 ' : '';
    switch (scope) {
        case 'all':
            return `${prefix}Ask any question about the report or transcript...`;
        case 'transcript':
            return `${prefix}Ask a question about the conversation transcript...`;
        case 'report':
            return `${prefix}Ask a question about the analysis report...`;
        default:
            return 'Ask your question...';
    }
};

export const ReportAssistant: React.FC<ReportAssistantProps> = ({ reportId }) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef('');
    const isInitializedRef = useRef(false);
    const [searchScope, setSearchScope] = useState<SearchScope>('all');
    const [useInternet, setUseInternet] = useState(false);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleScroll = () => {
        if (!messagesContainerRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
        const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
        setShowScrollButton(!isNearBottom);
    };

    useEffect(() => {
        const container = messagesContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
    }, [messages]);

    useEffect(() => {
        const loadChatHistory = async () => {
            if (isInitializedRef.current) return;

            try {
                const history = await assistantService.getChatHistory(reportId);
                if (history.length > 0) {
                    setMessages(history);
                    isInitializedRef.current = true;
                }
            } catch (error) {
                console.error('Failed to load chat history:', error);
            }
        };

        loadChatHistory();
    }, [reportId]);

    const updateMessage = useCallback((chunk: string) => {
        contentRef.current += chunk;
        setMessages(prev => {
            const newMessages = [...prev];
            const lastMessage = newMessages[newMessages.length - 1];
            lastMessage.content = contentRef.current;
            return newMessages;
        });
    }, []);

    const handleSend = async () => {
        if (!inputValue.trim() || isLoading) return;

        const userMessage: Message = {
            role: 'user',
            content: inputValue,
        };

        setMessages(prev => [...prev, userMessage]);
        setInputValue('');
        setIsLoading(true);
        contentRef.current = '';

        const assistantMessage: Message = {
            role: 'assistant',
            content: '',
        };

        setMessages(prev => [...prev, assistantMessage]);

        try {
            await assistantService.streamMessage(
                reportId,
                userMessage.content,
                chunk => {
                    requestAnimationFrame(() => updateMessage(chunk));
                },
                { search_scope: searchScope, use_web: useInternet }
            );
        } catch (error) {
            console.error('Error streaming message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const EmptyState = () => (
        <div className={styles.emptyState}>
            <Flex gap={6} vertical>
                <AseedTitle level={4} fontWeight={500}>
                    Ask your question to the assistant
                </AseedTitle>
                <AseedText description={true}>
                    Here you can ask any questions related to the current report and transcript. The assistant will help you understand the
                    details and find the necessary information.
                </AseedText>
            </Flex>
        </div>
    );

    return (
        <div className={styles.container}>
            <div ref={messagesContainerRef} className={styles.messagesContainer}>
                {messages.length === 0 ? (
                    <EmptyState />
                ) : (
                    messages.map((message, index) => (
                        <MessageBubble key={index} isRespondent={message.role === 'user'} text={message.content} />
                    ))
                )}
                <div ref={messagesEndRef} />
            </div>

            {showScrollButton && (
                <div className={styles.scrollToBottom} onClick={scrollToBottom}>
                    <DownOutlined />
                </div>
            )}

            <div className={styles.inputContainer}>
                <Input.TextArea
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    placeholder={getPlaceholderByScope(useInternet, searchScope)}
                    autoSize={{ minRows: 1, maxRows: 4 }}
                    onPressEnter={e => {
                        if (!e.shiftKey) {
                            e.preventDefault();
                            handleSend();
                        }
                    }}
                />
                <SearchSettings
                    searchScope={searchScope}
                    useInternet={useInternet}
                    onSearchScopeChange={setSearchScope}
                    onUseInternetChange={setUseInternet}
                />
                <button className={styles.buttonStyle} onClick={handleSend}>
                    􀈟
                </button>
            </div>
        </div>
    );
};
