import React, { createContext, useCallback, useContext, useState } from 'react';

import { profileService } from '../../services/profile.service';
import { BalancesResponse } from 'src/@types/profile';

interface BalanceContextType {
    balances: BalancesResponse | undefined;
    loading: boolean;
    refreshBalance: () => Promise<void>;
}

const BalanceContext = createContext<BalanceContextType | undefined>(undefined);

export const BalanceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [balances, setBalances] = useState<BalancesResponse>();
    const [loading, setLoading] = useState(false);

    const refreshBalance = useCallback(async () => {
        setLoading(true);
        try {
            const balancesData = await profileService.getBalances();
            setBalances(balancesData);
        } finally {
            setLoading(false);
        }
    }, []);

    return <BalanceContext.Provider value={{ balances, loading, refreshBalance }}>{children}</BalanceContext.Provider>;
};

export const useBalance = () => {
    const context = useContext(BalanceContext);
    if (!context) {
        throw new Error('useBalance must be used within a BalanceProvider');
    }
    return context;
};
