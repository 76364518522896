import config from '../config';
import authService from './auth.service';
import { firebaseAuth } from './firebase/AseedFirebase';
import { concatUrl } from './helpers/url-helper';
import axios from 'axios';

const { CUSTDEV_API_URL } = config;

export interface ChatMessage {
    id: string;
    message_type: 'user' | 'assistant';
    content: string;
    created_at: string;
}

export interface ChatHistory {
    messages: ChatMessage[];
}

export interface Message {
    role: 'user' | 'assistant';
    content: string;
}

export interface SearchSettings {
    search_scope: 'all' | 'transcript' | 'report';
    use_web: boolean;
}

class AssistantService {
    async getChatHistory(reportId: string): Promise<Message[]> {
        const url = new URL(concatUrl(CUSTDEV_API_URL, `jtbd/${reportId}/chat/`)).toString();

        try {
            const response = await axios.get<ChatHistory>(url);
            return response.data.messages.map(msg => ({
                role: msg.message_type,
                content: msg.content,
            }));
        } catch (error) {
            console.error('Failed to fetch chat history:', error);
            return [];
        }
    }

    private async handleTokenRefresh(originalRequest: () => Promise<Response>): Promise<Response> {
        try {
            const user = await authService.getCurrentUser();
            if (user) {
                const token = await user.getIdToken(true);
                return await originalRequest();
            }
            throw new Error('No user found');
        } catch (error) {
            await authService.logout();
            throw new Error('Authentication failed');
        }
    }

    async streamMessage(
        reportId: string,
        message: string,
        onChunk: (chunk: string) => void,
        search_settings: SearchSettings
    ): Promise<void> {
        const makeRequest = async (): Promise<Response> => {
            const url = new URL(concatUrl(CUSTDEV_API_URL, `jtbd/${reportId}/chat/`)).toString();
            const user = await authService.getCurrentUser();
            const token = user ? await user.getIdToken() : null;

            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...(token && { Authorization: `Bearer ${token}` }),
                },
                body: JSON.stringify({ message, search_settings }),
            });
        };

        try {
            let response = await makeRequest();

            if (response.status === 401) {
                response = await this.handleTokenRefresh(makeRequest);
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            if (!response.body) {
                throw new Error('Response body is null');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();

                if (done) break;

                const chunk = decoder.decode(value, { stream: true });
                onChunk(chunk);
            }
        } catch (error) {
            console.error('Streaming error:', error);
            throw error;
        }
    }
}

export const assistantService = new AssistantService();
