import { Button, Flex, Input, Select } from 'antd';

import { AseedText } from 'src/components/AseedTypography/AseedText/AseedText';
import { AseedTitle } from 'src/components/AseedTypography/AseedTitle/AseedTitle';
import { useReportSetupContext } from 'src/components/Report/ReportWidget/ReportSetupContext';

import { SetupCheckBox } from '../../../../../Menu/renders/CheckBoxes';
import { TEXT_AREA_GOALS, TEXT_AREA_NOTES } from '../../../../helpers/Placeholders';
import { AUTOMATIC_DETECTION, SAME_AS_TRANSCRIPT, createLanguageOptions, createParticipantOptions } from '../../../../helpers/SetupOptions';
import ReportGenerateMenu from '../../../ReportMenu/ReportGenerateMenu';
import { useReportWidgetContext } from '../../../ReportWidgetContext';
import { Language } from 'src/services/enums';

import styles from './ReportSetup.module.scss';

const { respondentWrapper, deleteBtn } = styles;

const MAX_PARTICIPANTS = 6;
const MAX_RESPONDENTS = 3;

const RespondentInput: React.FC<{
    maxRespondents: number;
    respondents: string[];
    onRespondentsChange: (respondents: string[]) => void;
}> = ({ maxRespondents, respondents, onRespondentsChange }) => {
    const handleInputChange = (index: number, value: string) => {
        const newRespondents = [...respondents];
        newRespondents[index] = value;
        onRespondentsChange(newRespondents);
    };

    const addRespondent = () => {
        const newRespondents = [...respondents, ''];
        onRespondentsChange(newRespondents);
    };

    const removeRespondent = (index: number) => {
        const newRespondents = respondents.filter((_, i) => i !== index);
        onRespondentsChange(newRespondents);
    };

    const currentParticipantCount = respondents.length < Math.min(maxRespondents - 1, MAX_RESPONDENTS);

    return (
        <Flex wrap="wrap" gap={12}>
            {respondents.map((respondent: string, index) => (
                <Flex key={index} className={respondentWrapper} vertical gap={9} style={{ width: 240 }}>
                    <Flex align="center" justify="space-between" style={{ minHeight: 24 }}>
                        <AseedText styles={{ fontSize: 16 }}>{index === 0 ? 'Respondent Name' : `Respondent Name ${index + 1}`}</AseedText>
                        {respondents.length > 1 && (
                            <button className={deleteBtn} onClick={() => removeRespondent(index)}>
                                􀈑
                            </button>
                        )}
                    </Flex>
                    <Input
                        placeholder="Respondent"
                        value={respondent}
                        onChange={e => handleInputChange(index, e.target.value)}
                        maxLength={24}
                    />
                </Flex>
            ))}
            {currentParticipantCount && (
                <Flex
                    vertical
                    gap={9}
                    style={{
                        opacity: 0.5,
                        transition: 'opacity 0.3s',
                        height: '78px',
                    }}
                    onMouseEnter={e => (e.currentTarget.style.opacity = '1')}
                    onMouseLeave={e => (e.currentTarget.style.opacity = '0.5')}
                >
                    <AseedText styles={{ fontSize: 16 }}>New Respondent Name</AseedText>

                    <Button
                        type="dashed"
                        style={{
                            width: 240,
                            height: 45,
                            borderRadius: 12,
                            marginTop: 'auto',
                        }}
                        onClick={addRespondent}
                    >
                        Add Another Respondent
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

export const ReportSetup: React.FC = () => {
    const { reportState, setReportState, isWithInsightReportState, isWithTranscriptState } = useReportSetupContext();

    const { report } = useReportWidgetContext();

    const [isWithTranscript, setIsWithTranscript] = isWithTranscriptState;
    const [isWithInsightReport, setIsWithInsightReport] = isWithInsightReportState;

    const handleInterviewLanguageChange = (value: string) => {
        setReportState(prevState => ({
            ...prevState,
            language: value === AUTOMATIC_DETECTION ? null : (value as Language),
        }));
    };

    const handleReportLanguageChange = (value: string) => {
        setReportState(prevState => ({
            ...prevState,
            report_language: value === SAME_AS_TRANSCRIPT ? null : value,
        }));
    };

    const handleParticipantChange = (value: string) => {
        setReportState(prevState => ({
            ...prevState,
            participant_count: value === AUTOMATIC_DETECTION ? null : parseInt(value),
        }));
    };

    const handleReportGoalsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReportState(prevState => ({
            ...prevState,
            goals: e.target.value || null,
        }));
    };

    const handleReportNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReportState(prevState => ({
            ...prevState,
            notes: e.target.value || null,
        }));
    };

    const handleRespondentsChange = (newRespondents: string[]) => {
        setReportState(prevState => ({
            ...prevState,
            respondent_known_names: newRespondents,
        }));
    };

    const getDisabledStyles = (isEnabled: boolean) =>
        ({
            filter: isEnabled ? 'none' : 'blur(2px)',
            pointerEvents: isEnabled ? 'auto' : 'none',
            opacity: isEnabled ? 1 : 0.7,
            transition: 'all 0.3s ease',
        }) as const;

    return (
        <Flex
            style={{
                padding: '30px 16px',
                width: '100%',
                justifyContent: 'center',
            }}
        >
            <Flex
                gap={48}
                vertical
                style={{
                    width: '100%',
                    maxWidth: 750,
                }}
            >
                <Flex gap={12} vertical>
                    <Flex vertical gap={0}>
                        <Flex justify="space-between">
                            <AseedTitle>Transcription</AseedTitle>
                            <SetupCheckBox
                                isLocked={!report || !report?.transcripts.length}
                                checked={isWithTranscript}
                                onChange={checked => setIsWithTranscript(checked)}
                            />
                        </Flex>
                    </Flex>
                    <div style={getDisabledStyles(isWithTranscript)}>
                        <Flex gap={12} vertical>
                            <AseedText description styles={{ fontSize: 15 }}>
                                For more accurate transcriptions, provide interview details. Optional, but it can make a difference.
                            </AseedText>
                            <Flex vertical gap={12}>
                                <Flex gap={12}>
                                    <Flex vertical gap={9} style={{ width: 240 }}>
                                        <AseedText styles={{ fontSize: 16 }}>Interview Language</AseedText>
                                        <Select
                                            style={{ width: '100%' }}
                                            disabled={true}
                                            options={createLanguageOptions(AUTOMATIC_DETECTION)}
                                            defaultValue={reportState.language || AUTOMATIC_DETECTION}
                                            onChange={handleInterviewLanguageChange}
                                        />
                                    </Flex>
                                    <Flex vertical gap={9} style={{ width: 240 }}>
                                        <AseedText styles={{ fontSize: 16 }}>Number of Participants</AseedText>
                                        <Select
                                            style={{ width: '100%' }}
                                            options={createParticipantOptions(MAX_PARTICIPANTS)}
                                            defaultValue={reportState.participant_count?.toString() || AUTOMATIC_DETECTION}
                                            onChange={handleParticipantChange}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex gap={12}>
                                    <RespondentInput
                                        maxRespondents={reportState.participant_count ?? MAX_PARTICIPANTS}
                                        respondents={reportState.respondent_known_names}
                                        onRespondentsChange={handleRespondentsChange}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                </Flex>

                <Flex gap={12} vertical>
                    <Flex vertical gap={0}>
                        <Flex justify="space-between">
                            <AseedTitle>Insight Report</AseedTitle>
                            <SetupCheckBox checked={isWithInsightReport} onChange={checked => setIsWithInsightReport(checked)} />
                        </Flex>
                    </Flex>
                    <div style={getDisabledStyles(isWithInsightReport)}>
                        <Flex gap={9} vertical>
                            <AseedText description styles={{ fontSize: 15 }}>
                                Provide product details and research goals to enhance report accuracy
                            </AseedText>
                            <Input.TextArea
                                placeholder={TEXT_AREA_GOALS}
                                autoSize={{ minRows: 6, maxRows: 10 }}
                                style={{ marginTop: 8 }}
                                onChange={handleReportGoalsChange}
                                value={reportState.goals || ''}
                                showCount
                                maxLength={10000}
                            />

                            <AseedText description styles={{ fontSize: 15, paddingTop: 16 }}>
                                Any additional info for more accurate result
                            </AseedText>

                            <Input.TextArea
                                placeholder={TEXT_AREA_NOTES}
                                autoSize={{ minRows: 3, maxRows: 6 }}
                                style={{ marginTop: 8 }}
                                onChange={handleReportNotesChange}
                                value={reportState.notes || ''}
                                showCount
                                maxLength={20000}
                            />

                            <Flex vertical gap={9} style={{ paddingTop: 16 }}>
                                <AseedText styles={{ fontSize: 16 }}>Report Language</AseedText>
                                <Select
                                    style={{ width: 240 }}
                                    options={createLanguageOptions(SAME_AS_TRANSCRIPT)}
                                    defaultValue={reportState.report_language || SAME_AS_TRANSCRIPT}
                                    onChange={handleReportLanguageChange}
                                />
                            </Flex>
                        </Flex>
                    </div>
                </Flex>
                <ReportGenerateMenu />
            </Flex>
        </Flex>
    );
};
