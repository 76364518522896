import { useEffect, useRef, useState } from 'react';

import { Flex, theme } from 'antd';

import { AseedText } from '../../../../AseedTypography/AseedText/AseedText';
import { AseedTitle } from '../../../../AseedTypography/AseedTitle/AseedTitle';
import { PointMaterial, Points } from '@react-three/drei';
import { Canvas, useFrame } from '@react-three/fiber';
import { random } from 'maath';
import * as THREE from 'three';

function ParticlesSphere({ mouseX }: { mouseX: number }) {
    const ref = useRef<THREE.Points>(null);
    const [sphere] = useState(() => new Float32Array(random.inSphere(new Float32Array(5000), { radius: 1.8 })));

    useFrame((state, delta) => {
        if (!ref.current) return;
        ref.current.rotation.x -= delta / 10;
        ref.current.rotation.y -= delta / 15;
        ref.current.rotation.z = mouseX * 0.001;
    });

    return (
        <group rotation={[0, 0, Math.PI / 4]}>
            <Points ref={ref} positions={sphere} stride={3} frustumCulled={false}>
                <PointMaterial transparent color="#7c3aed" size={0.02} sizeAttenuation={true} depthWrite={false} />
            </Points>
        </group>
    );
}

export const LoadingContent: React.FC = () => {
    const {
        token: { colorPrimary },
    } = theme.useToken();

    const [mouseX, setMouseX] = useState(0);
    const [dots, setDots] = useState('.');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots(prev => (prev.length >= 3 ? '.' : prev + '.'));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const handleMouseMove = (event: React.MouseEvent) => {
        setMouseX(event.clientX - window.innerWidth / 2);
    };

    return (
        <Flex
            justify="center"
            align="flex-start"
            onMouseMove={handleMouseMove}
            style={{
                height: 'calc(100vh - 150px)',
                paddingTop: '10%',
            }}
        >
            <Flex vertical gap={50}>
                <Flex style={{ width: 400, height: 400 }}>
                    <Canvas camera={{ position: [0, 0, 3] }}>
                        <ambientLight intensity={0.5} />
                        <ParticlesSphere mouseX={mouseX} />
                    </Canvas>
                </Flex>

                <Flex vertical gap={6}>
                    <Flex justify="center">
                        <AseedTitle level={2} fontWeight={400} styles={{ color: colorPrimary }}>
                            Planting the Seeds!
                        </AseedTitle>
                    </Flex>
                    <Flex justify="center">
                        <AseedText description>
                            wait, please
                            <span
                                style={{
                                    position: 'absolute',
                                    display: 'inline-block',
                                    width: '30px',
                                    textAlign: 'left',
                                }}
                            >
                                {dots}
                            </span>
                        </AseedText>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
