import { useEffect } from 'react';

import { analytics } from './AseedFirebase';
import { logEvent } from 'firebase/analytics';

interface EventParams {
    [key: string]: string | number | boolean;
}

export const trackEvent = (eventName: string, params?: EventParams) => {
    if (!analytics) {
        console.warn('Analytics is not initialized');
        return;
    }
    logEvent(analytics, eventName, params);
};

interface PageViewParams {
    [key: string]: string | number | boolean | undefined;
}

export const usePageView = (pageName: string, params: PageViewParams = {}) => {
    useEffect(() => {
        if (analytics) {
            logEvent(analytics, 'page_view', { page: pageName, ...params });
        }
    }, [pageName, params]);
};
