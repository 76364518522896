import React from 'react';

import { ConfigProvider } from 'antd';

import { Routes } from 'src/routes';

import './index.css';

function App() {
    return (
        <ConfigProvider
            theme={{
                cssVar: true,
                token: {
                    // colors
                    colorPrimary: '#8038FF',
                    colorLink: '#8038FF',
                    colorError: '#E57373',
                    colorWarning: '#E5964E',
                    colorInfo: '#8038FF',
                    colorTextSecondary: '#6A6A70',
                    fontSizeIcon: 30,

                    // text
                    fontFamily: 'SF Pro Text, sans-serif',
                    fontSizeHeading2: 32,
                    fontSizeHeading3: 24,
                    fontSizeXL: 22,
                    paddingXL: 36,
                },
                components: {
                    Layout: {
                        headerBg: '#4D4CD0',
                    },
                    Button: {
                        borderRadiusLG: 14,
                    },
                    Modal: {
                        borderRadiusLG: 24,
                        colorBgElevated: 'rgba(255, 255, 255, 0.7)',
                        headerBg: 'none',
                    },
                    Collapse: {
                        contentPadding: '0 42px',
                        paddingSM: 0,
                        headerPadding: '6px 12px',
                    },
                    Tabs: {
                        itemActiveColor: '#8038FF90',
                        titleFontSize: 18,
                        itemSelectedColor: '#8038FF90',
                        cardBg: '#8038FF10',
                        cardGutter: 5,
                    },
                    Select: {
                        fontSizeIcon: 16,
                        controlHeight: 45,
                        borderRadius: 12,
                    },
                    Input: {
                        controlHeight: 45,
                        borderRadius: 12,
                    },
                },
            }}
        >
            <Routes />
        </ConfigProvider>
    );
}

export default App;
