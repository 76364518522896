import config from '../../config';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { GithubAuthProvider, GoogleAuthProvider, getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const { FIREBASE_CONFIG } = config;

const app = initializeApp(FIREBASE_CONFIG);
export const firebaseStorage = getStorage(app);
export const firebaseAuth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const githubProvider = new GithubAuthProvider();
export const analytics = getAnalytics(app);
