import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { Modal, message } from 'antd';

import { useProjectListContext } from './ProjectListContext';
import { RcFile } from 'antd/es/upload';
import { CanceledError } from 'axios';
import { UploadMetadata, UploadTaskSnapshot, getMetadata, ref, uploadBytesResumable } from 'firebase/storage';
import * as mmb from 'music-metadata-browser';
import { firebaseAuth, firebaseStorage } from 'src/services/firebase/AseedFirebase';
import jtbdService from 'src/services/jtbd.service';
import { v4 as uuidv4 } from 'uuid';

export interface UploadFileTask {
    fileId: string;
    name: string;
    abortController: AbortController;
    uploadTask?: UploadTaskSnapshot;
}

type UploadFilesContext = {
    uploadNewFile: (file: RcFile) => Promise<string>;
    uploadFileTask?: UploadFileTask;
};

const UploadFilesCtx = createContext<UploadFilesContext>({
    uploadNewFile: async () => '',
});

export const UploadFilesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { fetchProjectList } = useProjectListContext();
    const [uploadFileTask, setUploadFileTask] = useState<UploadFileTask>();

    const uploadNewFile = useCallback(
        async (file: RcFile) => {
            const fileId = uuidv4();
            const abortController = new AbortController();
            const task = {
                fileId,
                name: removeFileExtension(file.name),
                abortController,
            };

            setUploadFileTask(task);

            try {
                await checkSpaceForUploading(file, abortController.signal);
                const duration = await getAudioDuration(file, abortController.signal);
                const metadata: UploadMetadata = {
                    customMetadata: {
                        duration: duration.toString(),
                        name: removeFileExtension(file.name),
                        fileId,
                    },
                };

                if (!firebaseAuth.currentUser?.emailVerified) {
                    throw new Error('User verification error');
                }
                const storageRef = ref(firebaseStorage, `audio/${firebaseAuth.currentUser.uid}/${fileId}/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file, metadata);
                setUploadFileTask({
                    ...task,
                    uploadTask: uploadTask.snapshot,
                });

                uploadTask.on(
                    'state_changed',
                    null,
                    error => {
                        console.error('Upload error:', error);
                        if (error.code === 'storage/unauthorized') {
                            message.error('Authorization error or wrong file type');
                        } else if (error.code === 'storage/canceled') {
                            abortController.abort();
                            console.info('Upload canceled');
                        } else {
                            message.error('Error uploading file');
                        }
                        setUploadFileTask(undefined);
                    },
                    async () => {
                        const metadata = await getMetadata(storageRef);
                        try {
                            await jtbdService.uploadAudio({ record_name: removeFileExtension(file.name), file_path: metadata.fullPath });
                        } catch (error) {
                            console.error('Upload error:', error);
                        }
                        setUploadFileTask(undefined);
                        fetchProjectList();
                    }
                );
            } catch (error) {
                if (error instanceof Error) {
                    message.error(error.message);
                } else {
                    console.error('Upload error:', error);
                }
                setUploadFileTask(undefined);
            } finally {
                return fileId;
            }
        },
        [fetchProjectList]
    );

    const values = useMemo(
        () => ({
            uploadNewFile,
            uploadFileTask,
        }),
        [uploadNewFile, uploadFileTask]
    );

    return <UploadFilesCtx.Provider value={values}>{children}</UploadFilesCtx.Provider>;
};

export const useUploadFileContext = (): UploadFilesContext => {
    const context = useContext(UploadFilesCtx);
    if (context === undefined) {
        throw new Error('useUploadFileContext must be used within a UploadFilesContext');
    }
    return context;
};

const checkSpaceForUploading = async (file: RcFile, abortSignal: AbortSignal): Promise<void> => {
    try {
        const fileSizeInMb = file.size / (1024 * 1024);
        const isEnoughSpace = await jtbdService.checkSpace(fileSizeInMb, abortSignal);
        if (!isEnoughSpace) {
            throw new Error('Not enough space');
        }
    } catch (error) {
        if (error instanceof CanceledError) {
            return;
        }
        Modal.error({
            title: 'Not enough space',
            content: 'You do not have enough space to upload this file. Please free up space by deleting old records.',
            okText: 'OK',
        });
        throw error;
    }
};

const removeFileExtension = (fileName: string): string => {
    return fileName.replace(/\.[^/.]+$/, '');
};

const getAudioDuration = async (file: RcFile, abortSignal: AbortSignal): Promise<number> => {
    try {
        const metadata = await mmb.parseBlob(file, { duration: true });
        if (metadata.format.duration) {
            return metadata.format.duration;
        }
        throw new Error('Duration not found in metadata');
    } catch (error) {
        return new Promise((resolve, reject) => {
            const media = document.createElement(file.type.startsWith('video/') ? 'video' : 'audio');
            const objectUrl = URL.createObjectURL(file);

            const cleanup = () => {
                URL.revokeObjectURL(objectUrl);
                media.remove();
            };

            media.addEventListener('loadedmetadata', () => {
                const duration = media.duration;
                cleanup();
                resolve(duration);
            });

            media.addEventListener('error', () => {
                cleanup();
                reject(new Error('Could not load media metadata'));
            });

            abortSignal.addEventListener('abort', () => {
                cleanup();
                reject(new Error('Operation cancelled'));
            });

            media.src = objectUrl;
        });
    }
};
