import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { notification } from 'antd';

import { provideNotification } from './helpers/notification-providers';
import config from 'src/config';
import authService from 'src/services/auth.service';
import { concatUrl } from 'src/services/helpers/url-helper';

const { CUSTDEV_WS_URL } = config;

type ReportNotificationContext = {
    socketEvent?: MessageEvent;
};

const ReportNotificationCtx = createContext<ReportNotificationContext>({});

export const ReportNotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [api, contextHolder] = notification.useNotification();
    const [socketEvent, setSocketEvent] = useState<MessageEvent>();
    const [reconnectTimerId, setReconnectTimerId] = useState<number | null>(null);
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const maxReconnectAttempts = 5;

    const wsUrl = getWebSocketUrl();

    useEffect(() => {
        let connection: ReturnType<typeof createWSConnection>;

        const initializeWebSocket = async () => {
            const user = await authService.getCurrentUser();
            if (!user) {
                console.error('User is not authenticated. Unable to establish WebSocket connection.');
                return;
            }

            const token = await user.getIdToken();
            connection = createWSConnection(wsUrl, token);
            const socket = connection.connect();

            socket.onopen = () => {
                console.log('WebSocket connection established');
                if (reconnectTimerId) {
                    clearTimeout(reconnectTimerId);
                    setReconnectTimerId(null);
                }
                setReconnectAttempts(0);
            };

            socket.onmessage = (event: MessageEvent) => {
                provideNotification(JSON.parse(event.data), api);
                setSocketEvent(event);
            };

            socket.onerror = (error: Event) => {
                console.error('WebSocket error:', error);
            };

            socket.onclose = async event => {
                if (event.code === 403) {
                    console.log('Token is invalid. Attempting to refresh...');
                    const user = await authService.getCurrentUser();
                    if (user) {
                        try {
                            const newToken = await user.getIdToken(true);
                            console.log('Token refreshed. Reconnecting...');
                            connection = createWSConnection(wsUrl, newToken);
                            connection.connect();
                        } catch (error) {
                            console.error('Failed to refresh token');
                            await authService.logout();
                        }
                    }
                } else if (reconnectAttempts < maxReconnectAttempts) {
                    console.log('Connection closed. Attempting to reconnect...');
                    const timerId = window.setTimeout(() => {
                        initializeWebSocket();
                    }, 5000);
                    setReconnectTimerId(timerId);
                    setReconnectAttempts(prev => prev + 1);
                } else {
                    console.error('Reconnection attempts limit reached.');
                }
            };
        };

        initializeWebSocket();

        return () => {
            if (reconnectTimerId) {
                clearTimeout(reconnectTimerId);
                setReconnectTimerId(null);
            }
            if (connection) {
                connection.disconnect();
            }
        };
    }, []);

    return (
        <ReportNotificationCtx.Provider value={{ socketEvent }}>
            {contextHolder}
            {children}
        </ReportNotificationCtx.Provider>
    );
};

export const useReportNotificationContext = (): ReportNotificationContext => {
    const context = useContext(ReportNotificationCtx);
    if (context === undefined) {
        throw new Error('useReportNotificationContext must be used within a ReportNotificationProvider');
    }
    return context;
};

function createWSConnection(wsUrl: string, token: string) {
    const socket = new WebSocket(`${wsUrl}?token=${token}`);

    return {
        connect(): WebSocket {
            return socket;
        },

        disconnect(): void {
            socket.close();
        },
    };
}

function getWebSocketUrl(): string {
    return new URL(concatUrl(CUSTDEV_WS_URL, '/notifications')).toString();
}
