import React from 'react';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

import { BalanceProvider } from '../components/Menu/BalanceContext';
import { ProjectListProvider } from '../components/UploadedFiles/ProjectListContext';
import { UploadFilesProvider } from '../components/UploadedFiles/UploadFilesContext';
import AseedMenu from 'src/components/Menu/Menu';

import { ReportNotificationProvider } from './ReportNotificationContext';

import styles from './MainLayout.module.scss';

export const MainLayout: React.FC = () => {
    return (
        <Layout>
            <BalanceProvider>
                <div className={mainLayout}>
                    <ReportNotificationProvider>
                        <main className={contentWrapper}>
                            <ProjectListProvider>
                                <UploadFilesProvider>
                                    <Outlet />
                                </UploadFilesProvider>
                            </ProjectListProvider>
                        </main>
                        <aside className={mainSidebar}>
                            <AseedMenu />
                        </aside>
                    </ReportNotificationProvider>
                </div>
            </BalanceProvider>
        </Layout>
    );
};

const { mainSidebar, mainLayout, contentWrapper } = styles;
