import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { GithubOutlined, GoogleOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, Form, FormProps, Input, Space, theme } from 'antd';

import authService from '../../services/auth.service';
import { firebaseAuth } from '../../services/firebase/AseedFirebase';
import { profileService } from '../../services/profile.service';
import { ModalContext } from '../Header/Header';
import { ModalPages } from './index';
import { PATH } from 'src/constants/paths';

type FieldType = {
    email: string;
    password: string;
    agree: boolean;
};

const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
};

interface SignInProps {
    onCancel: Dispatch<SetStateAction<void>>;
    setEmail: Dispatch<SetStateAction<string>>;
}

export const SignIn: React.FC<SignInProps> = ({ onCancel, setEmail }) => {
    const navigate = useNavigate();
    const {
        token: {
            colorPrimary,
            margin,
            marginMD,
            marginXL,
            marginLG,
            marginXXL,
            marginXS,
            sizeXXL,
            paddingLG,
            fontSizeLG,
            colorWhite,
            colorError,
        },
    } = theme.useToken();

    const [error, setError] = React.useState('');

    const onFinish: FormProps<FieldType>['onFinish'] = async ({ email, password }) => {
        try {
            await authService.signIn(email, password);
            console.log(firebaseAuth.currentUser);
            onCancel();
            await profileService.ensureProfile();
            navigate(PATH.WORKSPACE);
        } catch (error: any) {
            setError(error.message);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await authService.signInWithGoogle();
            console.log(firebaseAuth.currentUser);
            onCancel();
            await profileService.ensureProfile();
            navigate(PATH.WORKSPACE);
        } catch (error: any) {
            setError(error.message);
        }
    };

    const { setModalPage } = useContext(ModalContext);

    return (
        <Flex justify="center" wrap>
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="on"
                style={{ marginTop: marginXL, width: 330 }}
            >
                <Form.Item<FieldType>
                    name="email"
                    rules={[{ required: true, message: 'Please input your email' }]}
                    style={{ marginBottom: marginMD }}
                >
                    <Input
                        size="large"
                        placeholder="Your email"
                        style={{
                            height: sizeXXL,
                            borderRadius: sizeXXL,
                            paddingLeft: paddingLG,
                            paddingRight: paddingLG,
                            fontSize: fontSizeLG + 2,
                            color: '#6A6A70',
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        }}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    name="password"
                    rules={[{ required: true, message: 'Please input your password' }]}
                    style={{ marginBottom: marginMD }}
                >
                    <Input.Password
                        size="large"
                        placeholder="Your password"
                        style={{
                            height: sizeXXL,
                            borderRadius: sizeXXL,
                            paddingLeft: paddingLG,
                            paddingRight: paddingLG,
                            fontSize: fontSizeLG + 2,
                            color: '#6A6A70',
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        }}
                    />
                </Form.Item>
                <Flex justify="end" style={{ marginTop: -margin, marginBottom: marginLG }}>
                    <a onClick={() => setModalPage(ModalPages.ForgotPassword)} style={{ fontSize: fontSizeLG }}>
                        forgot password
                    </a>
                </Flex>

                <Form.Item>
                    <Button type="primary" size="large" htmlType="submit" style={{ borderRadius: sizeXXL, height: sizeXXL, width: '100%' }}>
                        Sign In
                    </Button>
                </Form.Item>

                <Divider>or</Divider>

                <Button
                    icon={<GoogleOutlined />}
                    size="large"
                    onClick={handleGoogleSignIn}
                    style={{ borderRadius: sizeXXL, height: sizeXXL, width: '100%' }}
                >
                    Sign In with Google
                </Button>

                <Divider style={{ borderColor: colorWhite, marginTop: marginXXL, color: colorError }}>{error}</Divider>

                <Flex justify="center" style={{ fontSize: fontSizeLG + 2, marginBottom: margin }}>
                    <div style={{ color: '#6A6A70', marginRight: marginXS }}>Want create an Account?</div>
                    <div style={{ color: colorPrimary, cursor: 'pointer' }} onClick={() => setModalPage(ModalPages.SignUp)}>
                        Sign Up
                    </div>
                </Flex>
            </Form>
        </Flex>
    );
};
