import React, { useEffect, useRef, useState } from 'react';

import styles from './EditableTitle.module.scss';

const MAX_TITLE_LENGTH = 100;
const WARNING_THRESHOLD = 80;

export const EditableTitle: React.FC<{
    value: string;
    onChange: (value: string) => void;
    isEditable: boolean;
    className?: string;
}> = ({ value, onChange, isEditable, className }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(value);
    const inputRef = useRef<HTMLInputElement>(null);

    const getInputStatus = (length: number) => {
        if (length >= MAX_TITLE_LENGTH) return 'error';
        if (length >= WARNING_THRESHOLD) return 'warning';
        return 'normal';
    };

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    const handleClick = () => {
        if (isEditable) {
            setIsEditing(true);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (inputValue.trim() !== value) {
            onChange(inputValue.trim());
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue.length > MAX_TITLE_LENGTH) {
            if (inputRef.current) {
                inputRef.current.classList.add(styles.shake);
                setTimeout(() => {
                    inputRef.current?.classList.remove(styles.shake);
                }, 500);
            }
            return;
        }
        setInputValue(newValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleBlur();
        }
        if (e.key === 'Escape') {
            setInputValue(value);
            setIsEditing(false);
        }
    };

    if (isEditing) {
        const status = getInputStatus(inputValue.length);
        return (
            <div className={styles.titleInputWrapper}>
                <input
                    ref={inputRef}
                    className={`${styles.projectTitle} ${styles.titleInput} ${styles[status]} ${className || ''}`}
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    aria-label="Project title"
                />
                {status !== 'normal' && (
                    <div className={`${styles.limitIndicator} ${styles[status]}`}>
                        {MAX_TITLE_LENGTH - inputValue.length} characters left
                    </div>
                )}
            </div>
        );
    }

    return (
        <div
            className={`${styles.projectTitle} ${isEditable ? styles.editable : ''} ${className || ''}`}
            onClick={handleClick}
            title={value}
        >
            {value}
        </div>
    );
};
