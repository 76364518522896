import React, { useEffect, useMemo, useState } from 'react';

import { Button, Flex, Modal, Typography, theme } from 'antd';

import { CurrencyRate } from '../../@types/profile';

const { Text, Title } = Typography;

interface TokenPurchaseModalProps {
    isOpen: boolean;
    onClose: () => void;
    onPurchase: (tokenCount: number, amount: number, currency: string) => void;
    currencyRate: CurrencyRate;
}

const TOKEN_COUNTS = [10, 1000, 2000, 5000, 10000, 20000];
const USD_TO_TOKEN_RATE = 100; // 1 USD = 100 tokens

export const TokenPurchaseModal: React.FC<TokenPurchaseModalProps> = ({ isOpen, onClose, onPurchase, currencyRate }) => {
    const [selectedCount, setSelectedCount] = useState(1000);
    const [isKazakhstan, setIsKazakhstan] = useState(true);
    const { token } = theme.useToken();

    useEffect(() => {
        const detectCountry = async () => {
            try {
                const response = await fetch('https://ipapi.co/json/');
                const data = await response.json();
                //setIsKazakhstan(data.country_code === 'KZ');
                setIsKazakhstan(true);
            } catch (error) {
                console.error('Error detecting country:', error);
                //setIsKazakhstan(false);
                setIsKazakhstan(true);
            }
        };

        detectCountry();
    }, []);

    const calculatePrice = (tokens: number) => {
        const usdPrice = tokens / USD_TO_TOKEN_RATE;
        if (isKazakhstan) {
            return usdPrice / currencyRate.kzt;
        }
        return usdPrice;
    };

    const getCurrencySymbol = () => (isKazakhstan ? '₸' : '$');

    const tokenPrice = isKazakhstan
        ? `${(currencyRate.kzt * USD_TO_TOKEN_RATE * 100).toFixed(2)} ₸`
        : `$${(1 / USD_TO_TOKEN_RATE).toFixed(2)}`;

    const getCurrency = () => (isKazakhstan ? 'KZT' : 'USD');

    return (
        <Modal open={isOpen} onCancel={onClose} footer={null} title={<Title level={4}>Buy Tokens</Title>} width={500}>
            <Text>Choose the number of tokens you'd like to purchase. These tokens can be used anytime and do not expire.</Text>

            <Flex wrap="wrap" gap="small" style={{ margin: '24px 0' }}>
                {TOKEN_COUNTS.map(amount => (
                    <Button
                        key={amount}
                        type={selectedCount === amount ? 'primary' : 'default'}
                        onClick={() => setSelectedCount(amount)}
                        style={{
                            flex: '1 1 30%',
                            margin: '4px',
                            borderRadius: token.borderRadius,
                            minWidth: '120px',
                        }}
                    >
                        {amount}
                    </Button>
                ))}
            </Flex>

            <Flex vertical gap="small" style={{ marginTop: '24px' }}>
                <Text>1 token = {tokenPrice}</Text>
                <Text>
                    Total: {getCurrencySymbol()}
                    {calculatePrice(selectedCount).toFixed(2)}
                </Text>

                <Button
                    type="primary"
                    size="large"
                    onClick={() => onPurchase(selectedCount, calculatePrice(selectedCount), getCurrency())}
                    style={{ marginTop: '16px' }}
                >
                    Proceed to Payment
                </Button>
            </Flex>
        </Modal>
    );
};
