import React, { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Affix, Button, Flex, theme } from 'antd';

import { useReportSetupContext } from '../ReportSetupContext';
import { useReportWidgetContext } from '../ReportWidgetContext';
import { useGenerateButtonProps, useGenerateReportApi } from './GenerateReportHook';

import styles from './ReportGenerateMenu.module.scss';

const ReportGenerateMenu: React.FC = () => {
    const [, generateReport] = useGenerateReportApi();
    const { buttonProps } = useGenerateButtonProps();
    const {
        token: { colorPrimary },
    } = theme.useToken();
    const {
        isWithTranscriptState: [isWithTranscript],
        isWithInsightReportState: [isWithInsightReport],
    } = useReportSetupContext();

    const [isAffixed, setIsAffixed] = useState(false);

    const { report } = useReportWidgetContext();

    const [generationPrice, setGenerationPrice] = useState<number>(0);

    useEffect(() => {
        let price = 0;

        if (isWithTranscript) {
            price += report?.expected_tokens.price_with_transcript || 0;
        }
        if (isWithInsightReport) {
            price += report?.expected_tokens.price_with_report || 0;
        }
        setGenerationPrice(price);
    }, [isWithInsightReport, isWithTranscript, report]);

    return (
        <Affix offsetBottom={15} onChange={affixed => setIsAffixed(!!affixed)}>
            <div className={`${styles.menuWrapper} ${isAffixed ? styles.affixed : ''}`}>
                <button className={styles.buttonStyle} onClick={() => generateReport()} disabled={buttonProps.disabled}>
                    <Flex vertical align="center" gap={0}>
                        <span>{buttonProps.text}</span>
                        {!buttonProps.disabled ? (
                            <span style={{ fontSize: '11px', fontWeight: 'normal' }}>{generationPrice} tokens</span>
                        ) : (
                            <span style={{ fontSize: '11px', fontWeight: 'normal', color: '#ff4d4f' }}>
                                Choose at least one type of analysis
                            </span>
                        )}
                    </Flex>
                </button>
                {buttonProps.disabled}
            </div>
        </Affix>
    );
};

export default ReportGenerateMenu;
