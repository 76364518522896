import { Tooltip } from 'antd';

import { AseedText } from './AseedText';
import { TranscriptExample } from 'src/@types/report';

const genSources = (dialog_examples: TranscriptExample[]) => (
    <div
        style={{
            padding: '6px 0',
        }}
    >
        {dialog_examples.map(dialog => (
            <AseedText description>
                <span style={{ fontStyle: 'italic', display: 'block', marginBottom: '6px' }}>- {dialog.text}</span>
            </AseedText>
        ))}
    </div>
);

export const SourcesTooltip: React.FC<{ dialogExamples: TranscriptExample[]; styles?: object }> = ({ dialogExamples, styles }) => (
    <Tooltip title={genSources(dialogExamples)} overlayInnerStyle={{ width: '550px' }} placement="topLeft" color="white">
        <span
            style={{
                marginLeft: '8px',
                padding: '2px 6px',
                backgroundColor: '#6A6A7020',
                borderRadius: '6px',
                fontSize: '12px',
                fontWeight: 'normal',
                height: '22px',
                ...styles,
            }}
        >
            {dialogExamples.length.toString()}
        </span>
    </Tooltip>
);
