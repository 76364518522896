import React from 'react';

import { Card, Flex, theme } from 'antd';

import ReportHeader from './ReportHeader';
import ReportTabs from './ReportTabController/ReportTabs';
import { ReportTabsProvider } from './ReportTabController/ReportTabsContext';
import { useReportWidgetContext } from './ReportWidgetContext';

const ReportWidget: React.FC = () => {
    const {
        token: { screenXL },
    } = theme.useToken();

    const { isLoadingReport, report, reportId } = useReportWidgetContext();

    const containerStyle = {
        maxWidth: screenXL - 100,
        transition: 'all 0.3s ease-in-out',
        margin: '0 auto',
    };

    const cardStyle = {
        flex: 1,
        borderRadius: 24,
        width: 320,
    };

    return (
        <div style={containerStyle}>
            {report && (
                <Flex vertical gap={12}>
                    <ReportHeader report={report} />
                    <Flex gap={20} wrap>
                        <Card loading={isLoadingReport} style={cardStyle}>
                            <ReportTabsProvider>
                                <ReportTabs report={report} reportId={reportId} />
                            </ReportTabsProvider>
                        </Card>
                    </Flex>
                </Flex>
            )}
        </div>
    );
};

export default ReportWidget;
