import React from 'react';

import { Dropdown, MenuProps, message } from 'antd';

import { DetailedReportResponse } from '../../../@types/jtbd';
import { trackEvent } from '../../../services/firebase/Analytics';
import { insightReportFormatter } from '../formatters/InsightReportFormatter';
import { transcriptFormatter } from '../formatters/TranscriptFormatter';

const COPY_REPORT = 'copyReport';
const DOWNLOAD_REPORT = 'downloadReport';
const DOWNLOAD_TRANSCRIPT = 'downloadTranscript';

interface ReportExportProps {
    report: DetailedReportResponse;
    className?: string;
}

const ReportExport: React.FC<ReportExportProps> = ({ report, className }) => {
    const hasReportContent = report.profile || report.insights || report.recommendations || report.jtbd;
    const hasTranscripts = report.transcripts && report.transcripts.length > 0;

    const handleDownloadClick = ({ key }: { key: string }) => {
        if (key === COPY_REPORT) {
            const formattedReport = insightReportFormatter.formatInsightReport(report);
            navigator.clipboard.writeText(formattedReport);
            message.success('Copied to buffer');
        } else if (key === DOWNLOAD_REPORT) {
            const formattedReport = insightReportFormatter.formatInsightReport(report);
            downloadFile(formattedReport, 'report.txt');
        } else if (key === DOWNLOAD_TRANSCRIPT) {
            const formattedTranscripts = transcriptFormatter.formatTranscripts(report.transcripts);
            downloadFile(formattedTranscripts, 'transcripts.txt');
        }
        trackEvent('button_click', { button_name: 'report_export' });
    };

    const downloadFile = (content: string, fileName: string) => {
        const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const documentElement = document.createElement('a');
        documentElement.href = url;
        documentElement.download = fileName;
        documentElement.click();
        URL.revokeObjectURL(url);
    };

    const items: MenuProps['items'] = [
        {
            key: COPY_REPORT,
            label: '􀐅 Copy report to Clipboard',
            disabled: !hasReportContent,
        },
        { type: 'divider' },
        {
            key: DOWNLOAD_REPORT,
            label: '􀫸 Download Report (.txt)',
            disabled: !hasReportContent,
        },
        {
            key: DOWNLOAD_TRANSCRIPT,
            label: '􀌮 Download Transcript (.txt)',
            disabled: !hasTranscripts,
        },
    ];

    if (!hasReportContent && !hasTranscripts) {
        return null;
    }

    return (
        <Dropdown trigger={['click']} menu={{ items, onClick: handleDownloadClick }}>
            <span className={className}>􀈆 Export</span>
        </Dropdown>
    );
};

export default ReportExport;
